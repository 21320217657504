import React from "react";

export default function VoidsOutOfPlace() {
  return (
    <main>
      <div className="publication-container">
        <div className="publication-upper">
          <img src="media/publications-imgs/29.jpg" alt="Publication" />
        </div>

        <div className="publication-text mt-5">
          <h2 className="mb-4">
            <a
              className="publication-link"
              target="_blank"
              rel="noreferrer"
              href="https://www.berghahnbooks.com/title/MartinezRepair"
            >
              What's in a Hole? Voids out of Place and Politics below the State
              in Georgia.
            </a>
            <br />
            In Repair, Brokenness, Breakthrough. F. Martínez & P. Laviolette
            (eds.)
            <br />
            Berghahn, 2019
          </h2>
          <p>
            This chapter provides a typology of different holes in Georgia to
            understand the connection between social and material forms of
            order. The research explores the way in which urban voids and
            material failures participate in the articulation of political
            discourses, coming to regulate public life, keeping people on hold,
            perpetuating or mediating a particular (subterranean) order. I make
            use of the representational qualities of holes to study the intense
            oscillation between lack and excess, hence holes are here taken as
            devices to think with. In their incompleteness, holes enable and
            defy human agency simultaneously, blending us into their gravity.
            Often, material failures become a sign of the inability of the state
            to act or invest in maintenance, but they also convey ‘voids’ in the
            relationship between state and society, standing as a form of
            communication or separation, connecting people to the state in
            qualitative ways.
          </p>
        </div>
      </div>
    </main>
  );
}
