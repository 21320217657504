import React from "react";

export default function TimeToFix() {
  return (
    <main>
      <div className="publication-container">
        <div className="publication-upper">
          <img src="media/publications-imgs/16.png" alt="Publication" />
        </div>

        <div className="publication-text mt-5">
          <h2 className="mb-4">
            <a
              className="publication-link"
              target="_blank"
              rel="noreferrer"
              href="https://journals.openedition.org/etnografica/14509"
            >
              Time to fix: Repair heuristics in Estonia and Portugal.
            </a>
            <br />
            Etnográfica, 2023
          </h2>
          <p>
            Accelerated cycles of production, consumption and discard, and the
            extensive availability of cheap mass-produced goods have led to
            increasingly quick processes of replacement through which a large
            number of things is rapidly discarded. Eventually, this has created
            a new problem, which is the increasing overflow and multiplicity of
            wastes, with materials that cannot be turned into value in a
            straightforward manner.
          </p>
          <p>
            On the other hand, past things often deserve a second chance.
            Chairs, cars, umbrellas, houses, relationships, you name it. In some
            cases, they had fallen apart because of wrong use or design, because
            of being available too early or too late, done with materials that
            are not good enough, or in turn because of not being subject of
            maintenance work. While working on how to give a second chance to
            things, we can learn the way objects are made, their
            vulnerabilities, and eventually how they can also be re-designed.
            However, repair is hardly considered for future projections.
          </p>
          <p>
            An ethnographic attention to fixing interventions allows us to
            comprehend the processes and conditions under which certain things
            acquire socio-material stability against the grain. Also, it shows
            that repair work is socially and politically-loaded by putting
            things to some order and by activating other kinds of relations.
            Indeed, the repair of things contributes to stabilise human life
            insofar as they give it a continuity and order.
          </p>
        </div>
      </div>
    </main>
  );
}
