import React from "react";
import { Helmet } from "react-helmet";

export default function Introduction() {
  return (
    <main className="introduction">
      <Helmet>
        <title>Francisco Martinez - Introduction</title>
        <meta
          name="description"
          content="More About Francisco Martinez. Anthropologist dealing with contemporary issues of material culture through ethnographic experiments."
        />
        <meta
          name="keywords"
          content="anthropology, ethnography, material culture, political discourses, repair practices, francisco martinez"
        />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8">
            <p>
              Currently, I work at Tampere University (Finland) within the{" "}
              <a
                className="publication-link"
                target="_blank"
                rel="noreferrer"
                href="https://projects.tuni.fi/wastematters/"
              >
                ERC project WasteMatters
              </a>
              . From September 2025, I will be a{" "}
              <span className="fw-bold">
                Ramón y Cajal Senior Research Fellow
              </span>{" "}
              at the University of Murcia, Spain.
            </p>
            <p>
              Stemming from the international cooperation forged through my
              publications, grants, teaching and research experience, I have had
              the chance to regularly share my work in prestigious institutions
              across Europe, the UK, Russia and the Americas.
            </p>
            <p>
              For instance, I have worked at the University of Helsinki,
              Leicester University, Aalto University, Tallinn University and the
              Estonian Academy of Arts, and enjoyed visiting fellowships at
              Humboldt University in Berlin, Vilnius Academy of Arts, Art
              Academy of Latvia, Higher School of Economics of Moscow, Ilia
              State University, Moscow School of Diplomacy and International
              Relations, University of Lisbon, Aleksanteri Institute, and the
              Creative Campus of the Andres Bello University, Chile.
            </p>
            <p>
              In the field of European anthropology, I am known for my fresh and
              insightful contributions, which were recognized after being
              awarded the 2018{" "}
              <span className="fw-bold">
                Early Career Prize by the European Association of Social
                Anthropologists
              </span>
              .
            </p>
            <p>
              Other evidence of my professional achievements includes the
              appointments in diverse editorial boards, the convenorship of
              Colleex (
              <a
                className="publication-link"
                target="_blank"
                rel="noreferrer"
                href="https://colleex.wordpress.com/"
              >
                EASA Network for Experimental Collaborations
              </a>
              ), and editing the Berghahn book series{" "}
              <a
                className="publication-link fst-italic"
                target="_blank"
                rel="noreferrer"
                href="https://www.berghahnbooks.com/series/politics-of-repair"
              >
                Politics of Repair
              </a>{" "}
              (with P. Laviolette).
            </p>
          </div>
          <div className="col-12 col-md-4 d-inline-flex align-items-start justify-content-center text-center mb-2 mb-md-1">
            <img
              src="media/Introduction1.jpg"
              alt="Francisco"
              className="img-fluid img-standard"
              style={{ width: "20rem" }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <p>
              My research has sought to explore different forms of material care
              and governance. For instance, my new monograph is{" "}
              <span className="fw-bold fst-italic">The Future of Hiding</span>,
              which will be published by Cornell University Press in 2025,
              examines how a particular kind of sociality of secrecy is
              constituted in relation to extractive activities and as the side
              effect of infrastructural harm.
            </p>
            <p>
              Much of my research concerns how people relate to traces from the
              past as well as reconsiderations of what is deemed as unworthy of
              salvage. In{" "}
              <a
                className="publication-link fst-italic"
                target="_blank"
                rel="noreferrer"
                href="https://uclpress.co.uk/book/remains-of-the-soviet-past-in-estonia/"
              >
                Remains of the Soviet Past in Estonia
              </a>
              , for instance, I studied memory politics in relation to the lack
              of maintenance work of the socialist legacies, which apparently
              had no place in the national present. As the ethnography shows,{" "}
              <span className="fw-bold">the reparation of things</span>,
              especially those that seem wasted,{" "}
              <span className="fw-bold">
                can be a way of shaping political subjectivities
              </span>
              .
            </p>
            <p>
              Likewise, I have edited{" "}
              <a
                className="publication-link fst-italic"
                target="_blank"
                rel="noreferrer"
                href="https://www.routledge.com/Politics-of-Recuperation-Repair-and-Recovery-in-Post-Crisis-Portugal/Martinez/p/book/9780367673048"
              >
                Politics of Recuperation in Post-crisis Portugal
              </a>
              , a volume that discusses how the Portuguese society rebuilt
              itself after a painful economic crisis and imposed austerity
              measures.
            </p>
            <p>
              And{" "}
              <a
                className="publication-link fst-italic"
                target="_blank"
                rel="noreferrer"
                href="https://www.berghahnbooks.com/title/MartinezRepair"
              >
                Repair, Brokenness, Breakthrough
              </a>{" "}
              (edited with P. Laviolette), which explains that the relevance of
              repair is not that happens, but rather the values attached to it,
              as well as the aesthetic, political, and moral implications that
              this gesture entails.
            </p>
            <p>
              By studying how we voluntarily reuse things and how these
              practices affect our relation with other people and the world, we
              can learn about the resignification of material imaginaries that
              were historically associated with poverty and are now acquiring a
              new symbol as related to sustainability and the awareness of
              planetary limits. But, once assuming that we have to give up some{" "}
              <span className="fw-bold">consumption</span>, we firstly have to
              understand <span className="fw-bold">how and what kind of?</span>
            </p>
          </div>
          <div className="col-12 col-md-4 d-inline-flex align-items-center justify-content-center text-center mb-2 mb-md-1">
            <img
              src="media/Introduction2.jpg"
              alt="Francisco"
              className="img-fluid img-standard img-righty"
              style={{ objectPosition: "25% 50%" }}
            />
          </div>
          <div className="col-12 col-md-8">
            <p>
              In the theme issue ‘
              <a
                className="publication-link fst-italic"
                target="_blank"
                rel="noreferrer"
                href="https://www.tandfonline.com/doi/full/10.1080/17406315.2024.2347703"
              >
                Living with and without Things
              </a>
              ’ (edited with T. Errázuriz), I investigated how having fewer
              things provides the opportunity to engage more intensely with them
              and deepen relationship with the items we chose to keep.
            </p>
            <p>
              However, alternative forms of consumption do not follow a coherent
              pattern. Thus, actual ways of pondering the line between essential
              products and those that are not deserve a nuanced ethnographic
              understanding.
            </p>
            <p>
              Our societies require more sophisticated research methods. In this
              vein, my work tends to address complex problems beyond
              disciplinary boundaries, paying close attention to the improvement
              of methodologies, while being committed to innovative and publicly
              relevant inquiries.
            </p>
          </div>
          <div className="col-12">
            <p>
              I am particularly interested in how things and ideas unfold in
              time and space, and in the{" "}
              <span className="fw-bold">material modes of knowing</span>. That
              is why I edited the volume{" "}
              <a
                className="publication-link fst-italic"
                target="_blank"
                rel="noreferrer"
                href="https://www.routledge.com/Peripheral-Methodologies-Unlearning-Not-knowing-and-Ethnographic-Limits/Martinez-Puppo-Frederiksen/p/book/9780367757625"
              >
                Peripheral Methodologies
              </a>{" "}
              with L. Di Puppo and M.D. Frederiksen.
            </p>
            <p>
              My work has also carved a strongly independent and creative path,
              cultivating experimentality in ethnographic methods and material
              culture studies. An example of this is my{" "}
              <span className="fw-bold">curation of eleven exhibitions</span>.
            </p>
            <p>
              For the exhibition{" "}
              <a
                className="publication-link fst-italic"
                target="_blank"
                rel="noreferrer"
                href="https://arterritory.com/en/visual_arts/reviews/25747-the_wound_that_needs_healing/"
              >
                Life in Decline
              </a>{" "}
              (Estonian Mining Museum, 2021), I collaborated with a dozen of
              local artists and designers to represent ethnographically how
              infrastructure and people endure in a context of exhausted
              ecologies.
            </p>
            <p>
              Also, I have{" "}
              <span className="fw-bold">
                combined material culture with creative research
              </span>{" "}
              in the project{" "}
              <a
                className="publication-link fst-italic"
                target="_blank"
                rel="noreferrer"
                href="https://roadsides.net/wp-content/uploads/martinez-agu-005.pdf"
              >
                Greetings from Another Land and Another Time
              </a>{" "}
              (with M. Agu at the Museum of Contemporary Art of Estonia, 2018).
              We gathered a series of postcards showing landscapes sacrificed by
              Soviet modernization in the twentieth century, and complemented
              them with practices of contemporary archaeology and archival
              research. The juxtaposition of different imaginaries of pollution
              allowed us to understand how embodied contamination are located in
              landscapes.
            </p>
            <p>
              Another example of establishing a bridge between scholars and non-
              academic actors is{" "}
              <span className="fw-bold fst-italic">Objects of Attention</span>,
              organised at the Estonian Museum of Applied Art &amp; Design. In
              this case, I invited ten international artists to revise an
              ordinary object into a political question. Based on this
              experience, I wrote{" "}
              <a
                className="publication-link fst-italic"
                target="_blank"
                rel="noreferrer"
                href="https://uclpress.co.uk/book/ethnographic-experiments-with-artists-designers-and-boundary-objects/"
              >
                Ethnographic Experiments with Artists, Designers and Boundary
                Objects
              </a>{" "}
              (UCL Press, 2021).
            </p>
            <p>
              Current changes in knowledge production impel us to combine
              different models of knowledge creation and dissemination and to{" "}
              <span className="fw-bold">include actors in new ways</span>,
              encouraging reciprocity between academic and non-academic
              questioning.
            </p>
            <p>
              For instance, the project{" "}
              <a
                className="publication-link fst-italic"
                target="_blank"
                rel="noreferrer"
                href="https://news.err.ee/1609109393/feature-new-art-exhibition-explores-hidden-secrets-of-ida-viru-county"
              >
                Keeping Things in the Dark
              </a>{" "}
              was developed through three exhibitions: in the Riga Art Space
              (2022), Sillamäe Museum (2023), and the EKA gallery of Tallinn
              (2023). The artworks were based on ethnographic research with
              three local artists—Anna Škodenko, Darja Popolitova and Viktor
              Gurov, investigating the material culture on the border between
              Russia and Estonia, characterized by secrecy and mistrust. As we
              learnt after visiting 37 basements in this region, shadow spaces
              play an important role to testing multiple sides of the self.
            </p>
          </div>
          <div className="col-12 col-md-8">
            {" "}
            <p>
              And with Viktor Gurov, I have curated three exhibitions in 2024,
              trying to understand the role of books and libraries in
              contemporary socities:{" "}
              <a
                className="publication-link fst-italic"
                target="_blank"
                rel="noreferrer"
                href="https://news.err.ee/1609331730/feature-new-art-exhibition-explores-sillamae-library-that-was-left-behind"
              >
                Ex Libris
              </a>{" "}
              (SillArt, Sillamäe),{" "}
              <span className="fw-bold fst-italic">
                General Theory of Abandoned Books
              </span>{" "}
              (Glassbox, Solaris Center), and{" "}
              <span className="fw-bold fst-italic">Misreadings</span> (Container
              Gallery, Telliskivi Creative City).
            </p>
            <p>
              Overall, my commitment to innovative research and versatile
              profile, along with my ability to{" "}
              <span className="fw-bold">
                acquire competitive funding from external bodies
              </span>{" "}
              emphasises my leadership abilities. For instance, I managed to
              secure research funding from institutions such as the Erasmus
              Mundus, ERC Mobilitas, Jean Monnet Module, Estonian Ministry of
              Education, Interreg, Spanish Ministry of Education, Spanish
              Embassy to Estonia, and the Finnish Cultural Institute to name a
              few.
            </p>
          </div>
          <div className="col-12 col-md-4 d-inline-flex align-items-center justify-content-center text-center mb-2 mb-md-1">
            <img
              src="media/Introduction3.jpg"
              alt="Francisco"
              className="img-fluid img-standard img-righty"
              style={{ objectPosition: "70% 50%" }}
            />
          </div>
        </div>
      </div>
    </main>
  );
}
