import React from "react";

export default function MutualRedefinitions() {
  return (
    <main>
      <div className="publication-container">
        <div className="publication-upper">
          <img src="media/publications-imgs/43.jpg" alt="Publication" />
        </div>

        <div className="publication-text mt-5">
          <h2 className="mb-4">
            Mutual Redefinitions: Anthropology as a Practice and as a
            Discipline.
            <br />
            Social Anthropology, 2024.
          </h2>
          <p>
            This article provides a new take on the possibilities – and limits –
            of experimental collaborations for ethnographic research and how
            they might lead to shifts back in the disciplinary core. By showing
            how exhibitions can be used for knowledge-making, it expands the
            notion of field and fieldwork. The series of ethnographic
            experiments outlined here help us rethink and, eventually, unlearn
            our own tools and protocols of relevance in anthropology.
            Unlearning, however, is not what researchers are supposed to do in
            the field. Too-often boundary-work removes what gives rise to wonder
            in order to secure what counts as valid knowledge and by whom. A
            more inclusive take on fieldwork could benefit practitioners and the
            discipline itself, as anthropologists are seeking to reach wider
            publics and navigate the contemporary complexities of authority and
            representation.
          </p>
        </div>
      </div>
    </main>
  );
}
