import React from "react";

export default function ActsOfReDesigning() {
  return (
    <main>
      <div className="publication-container">
        <div className="publication-upper">
          <img src="media/publications-imgs/37.jpeg" alt="Publication" />
        </div>

        <div className="publication-text mt-5">
          <h2 className="mb-4">
            Acts of re-design: For an anthropology of field interventions, with
            T.S. Criado and E. Berglund.
            <br />
            Forthcoming
          </h2>
          <p>
            This article discusses anthropology as an interventionist practice
            inspired by design approaches. Firstly, we consider some inspiring
            design projects we have encountered and review the existing
            literature on design anthropology. Then, we provide examples from
            our own ethnographic research, unpacking the in-between practice of
            ‘re-designing’. Our article stems from an interest to open up and
            equip the venues where ethnography is done in order to put the
            social in active suspension while studying it. That is why we
            propose to practice anthropology as a form of re-design,
            materialising inventive ways of entering ethnographic conversations.
            This is not an easy task, we reckon. It requires learning to
            appreciate the rich terrain where anthropological and design
            practices mingle. Finally, in addition to discussing the role design
            has taken in recent anthropological endeavours, we also attempt to
            think how we could be re-designing our discipline back, outlining
            possible ways.
          </p>
        </div>
      </div>
    </main>
  );
}
