import React from "react";

export default function Escondites() {
  return (
    <main>
      <div className="publication-container">
        <div className="publication-upper">
          <img
            src="media/publications-imgs/2_original.jpeg"
            alt="Publication"
          />
        </div>

        <div className="publication-text mt-5">
          <h2 className="mb-4">
            Escondites. Un descenso a lo secreto y sus espacios. En proceso.
          </h2>
          <p>
            En los escondites, los secretos encuentran un espacio. Son el lugar
            en el mundo para la desviación y la invención, la esquina donde se
            deposita la mirada oblicua hacia lo normativo. Las personas
            necesitamos escondites donde guardar ciertas cosas en la oscuridad.
            Cosas que no deben ser visibles, cosas que podrían adquirir otro
            valor con el trabajo del tiempo, cosas ocultas bajo tierra para
            poder tener una imagen limpia en otro lugar más público o ante los
            demás.
          </p>
          <p>
            Este libro reivindica la necesidad de que algunos espacios sean
            secretos y opacos. A través de ejemplos etnográficos en seis países,
            Alemania, España, Estonia, Finlandia, Georgia y Portugal, elabora un
            argumento para defender las zonas esquinadas de la sociedad,
            aquellas que promulgan formas diferentes de hacer y de estar en el
            mundo, frente a las actuales prácticas de hipervisibilidad e
            inspección. En una sociedad red fascinada por los simulacros de
            transparencia y donde la vigilancia digital registra cada uno de
            nuestros actos, la posibilidad de encontrar escondites se ha
            convertido en una forma de protección de la libertad individual, e
            incluso una cuestión de bienestar social.
          </p>
          <h4>Praise</h4>
          <p className="font-monospace">
            El escondite es donde la historia comienza de nuevo. Una invitación
            a escapar, a abrirse paso hacia el otro lado o revelar algo
            desconocido. Los escondites que muestra Martínez están más o menos
            ocultos a la vista, pero acechan no muy lejos de la superficie y de
            nuestro pasado, presente y futuro. Es en este sentido, son
            tecnologías de nuestras conciencias, identidades y recuerdos.
            <br />
            <span className="fw-bold fst-italic text-decoration-underline">
              Patrick Laviolette, Ethnologia Europaea
            </span>
          </p>
          <p className="font-monospace">
            Los escondites ofrecen un terreno particularmente fértil para
            explorar los límites de las relaciones. En su crítica de la
            hipervisibilidad como fuerza normalizadora, Martínez pide que
            entrenemos nuestras prácticas corporales de atención. Él domina el
            arte de la ocultación y la autoexposición, y sabe cómo llegar a este
            tipo de lugares vibrantes.
            <br />
            <span className="fw-bold fst-italic text-decoration-underline">
              Hermione Spriggs, University College London
            </span>
          </p>
          <p className="font-monospace">
            En este libro, la opacidad es elevada a la categoría de derecho en
            un mundo donde “lo bueno” está asociado con la transparencia. De
            esta forma, los escondites ya no tienen el valor negativo que se le
            adjudica desde una política de la hipervisibilidad, sino que se
            convierten en una forma de resistencia a ésta.
            <br />
            <span className="fw-bold fst-italic text-decoration-underline">
              Mariana Tello Weiss, Universidad Nacional de Córdoba
            </span>
          </p>
          <p className="font-monospace">
            Martínez propone los escondites como un espacio donde ampliar
            nuestra capacidad de cambiar los términos de nuestra vida, al tiempo
            que cuestiona qué condiciones de existencia vale la pena perseguir y
            qué proyectos políticos hay que defender para preservarlas.
            <br />
            <span className="fw-bold fst-italic text-decoration-underline">
              Tamta Khalvashi, Ilia State University of Tbilisi
            </span>
          </p>
        </div>
      </div>
    </main>
  );
}
