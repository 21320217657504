import React from "react";

export default function RemainsOftheSovietTime() {
  return (
    <main>
      <div className="publication-container">
        <div className="publication-upper">
          <img src="media/publications-imgs/4.jpeg" alt="Publication" />
        </div>

        <div className="publication-text mt-5">
          <h2 className="mb-4">
            Remains of the Soviet Time in Contemporary Estonia. UCL Press, 2018.
          </h2>
          <p>
            This book addresses the material construction of temporal regimes
            after communism and what happens to legacies that do not find any
            continuation. In Estonia, a new generation that does not remember
            the socialist era and is open to global influences has grown up. As
            a result, the impact of the Soviet memory in people’s conventional
            values is losing its effective power, opening new opportunities for
            repair and revaluation of the past.
          </p>
          <p>
            The ethnographic research brings together a number of sites of
            interest to explore the vanquishing of the Soviet legacy in Estonia:
            the railway bazaar in Tallinn where concepts such as ‘market’ and
            ‘employment’ take on distinctly different meanings from their
            Western use; Linnahall, a grandiose venue, whose Soviet heritage now
            poses difficult questions of how to present the building’s history;
            Tallinn’s cityscape, where the social, spatial and temporal
            co-evolution of the city can be viewed and debated; Narva, a city
            that marks the border between the Russian Federation, NATO and the
            European Union, and represents a place of continual negotiation of
            belonging; and the new Estonian National Museum in Raadi, an area on
            the outskirts of Tartu, that has been turned into a memory field.
            The study of all these places shows that national identity and
            historical representations can be constructed in relation to waste
            and disrepair too.
          </p>
          <h4>Praise</h4>
          <p className="font-monospace">
            By adopting the tropes of ‘repair’ and ‘waste’, this book
            innovatively manages to link various material registers from
            architecture, intergenerational relations, affect and museums with
            ways of making the past present. Through a rigorous yet
            transdisciplinary method, Martínez brings together different scales
            and contexts that would often be segregated out. In this respect,
            the ethnography unfolds a deep and nuanced analysis, providing a
            useful comparative and insightful account of the processes of repair
            and waste making in all their material, social and ontological
            dimensions.
            <br />
            <span className="fw-bold fst-italic text-decoration-underline">
              Victor Buchli, University College London
            </span>
          </p>
          <p className="font-monospace">
            This book comprises an endearingly transdisciplinary ethnography of
            postsocialist material culture and social change in Estonia.
            Martínez creatively draws on a number of critical and cultural
            theorists, together with additional research on memory and political
            studies scholarship and the classics of anthropology. Grappling
            concurrently with time and space, the book offers a delightfully
            thick description of the material effects generated by the
            accelerated post-Soviet transformation in Estonia, inquiring into
            the generational specificities in experiencing and relating to the
            postsocialist condition through the conceptual anchors of wasted
            legacies and repair. This book defies disciplinary boundaries and
            shows how an attention to material relations and affective
            infrastructures might reinvigorate political theory.
            <br />
            <span className="fw-bold fst-italic text-decoration-underline">
              Maria Mälksoo, University of Kent
            </span>
          </p>
          <p className="font-monospace">
            In its examination of the material and social ‘afterlife’ of the
            Soviet world in contemporary Estonia, the ethnography is concerned
            less with how residual elements of communism are currently being
            invoked or commemorated, and more with how people are engaging with
            these remains through distinct yet interrelated acts of neglect,
            recovery and repair… Martínez is a deeply self‐reflexive
            anthropologist… He employs inventive methodologies throughout his
            fieldwork, interwoven with a range of analytical perspectives to
            construct a project that proves to be a unique combination of
            ethnography, cultural critique, reportage and personal memoir… Part
            of what adds to the originality of this analysis is that Martínez
            becomes an instigator of artistic and playful experiments that
            supplement his interviews, focus groups and practices of participant
            observation.
            <br />
            <span className="fw-bold fst-italic text-decoration-underline">
              Alyssa Grossman, Social Anthropology 2019
            </span>
          </p>
          <p className="font-monospace">
            By engaging, and not just observing, he deploys the kind of
            interpretive reflexivity that is critical to understand the research
            context, in other words, how ideas are appropriated anew, legacies
            passed on to younger generations, and new meanings ascribed to old
            materialities that persist in physical and affective landscapes”
            <br />
            <span className="fw-bold fst-italic text-decoration-underline">
              Marcos F. Ferreira, Anthropological Journal of European Cultures,
              2020
            </span>
          </p>
          <p className="font-monospace">
            Laiapõhjalise, sageli väga pehme ja autorile justkui tee peale
            sattunud materjali pinnalt – olgu need siis vestlused oma partneri
            emaga, juhuslikud kohtumised ning mõttevahetused sõprade, tuttavate
            ja ka võhivõõrastega Eestis – on Martinez suutnud kokku panna uudse
            ja inspireeriva teose.
            <br />
            <span className="fw-bold fst-italic text-decoration-underline">
              Tauri Tuvikene, Müürileht, 2018.
            </span>
          </p>
        </div>
      </div>
    </main>
  );
}
