import React from "react";

export default function Peripheristan() {
  return (
    <main>
      <div className="publication-container">
        <div className="publication-upper">
          <img src="media/publications-imgs/39.jpeg" alt="Publication" />
        </div>

        <div className="publication-text mt-5">
          <h2 className="mb-4">
            Peripheristan. In Anti-Atlas. Ed. by W. Bracewell, T. Beasley, & M.
            Murawski.
            <br />
            UCL Press, 2025
          </h2>
          <p>
            This entry to the Anti-Atlas sketches the outlines of a
            transnational continent of the (semi-) fringes. Less Moscow or
            London, more Leicester, Tampere and Tallinn, as his own career
            evidences. The much-attested disadvantages of peripheral status,
            including the centres’ refusal to see such locales on their own
            terms, are here compensated by the advantages (ambiguity and
            distance) of relative remoteness – though peripherality can be as
            much a political condition as a state of mind and a geography of
            displacement.
          </p>
        </div>
      </div>
    </main>
  );
}
