import React from "react";

export default function InsightsIntoTheDemolition() {
  return (
    <main>
      <div className="publication-container">
        <div className="publication-upper">
          <img src="media/publications-imgs/42.jpg" alt="Publication" />
        </div>

        <div className="publication-text mt-5">
          <h2 className="mb-4">
            Half-Empty: Insights on the Demolition of Soviet Housing in
            North-East Estonia.
            <br />
            With K. Kljavin.
            <br />
            Forthcoming.
          </h2>
          <p>
            This article provides an understanding of how demolition is tested
            as a tool to deal with the problem of half-emptiness in a
            postindustrial setting. The research examines a pilot project that
            sets out to regenerate the urban fabric through the liquidation of
            privately-owned, semi- vacant apartment buildings in Kohtla-Järve, a
            town developed in relation to intensive mining during the Soviet
            time and mostly inhabited by Russian speakers. The sacrifice of
            housing was presented by state authorities as a way of increasing
            real estate prices and as a rehearsal of policy models to be applied
            elsewhere. The article’s contribution is thus twofold: it unpacks
            the concept of “half-empty” and explains how the demolition strategy
            is perceived by different stakeholders.
          </p>
        </div>
      </div>
    </main>
  );
}
