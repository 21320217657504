import React from "react";

export default function CómoCurar() {
  return (
    <main>
      <div className="publication-container">
        <div className="publication-upper">
          <img src="media/publications-imgs/33.png" alt="Publication" />
        </div>

        <div className="publication-text mt-5">
          <h2 className="mb-4">
            <a
              className="publication-link"
              target="_blank"
              rel="noreferrer"
              href="https://xcol.org/invention/como-curar-y-cuidar-de-nuestras-preguntas-etnograficas-de-forma-colaborativa-y-experimental/"
            >
              Cómo curar (y cuidar) de nuestras preguntas etnográficas de forma
              colaborativa y experimental.
            </a>
            <br />
            Xcol 2023
          </h2>
          <p>
            ¿Cómo sería una exposición que plantee preguntas etnográficas en
            lugar de presentar categorías fijadas de antemano? Este artículo
            describe cómo organizar exposiciones como dispositivos etnográficos:
            no únicamente como intentos de comunicar resultados de
            investigación, sino en tanto que técnicas para inventar, re-diseñar
            y cuestionar críticamente una realidad. El uso de exposiciones para
            reconfigurar lo que podría ser el conocimiento y lo político permite
            hacer que el trabajo de campo sea más experimental y colaborativo.
            Esta manera de investigar contribuye a distribuir la capacidad
            analítica entre los diferentes actores involucrados en la
            exposición, además de hacer al público partícipe de nuestras
            re-presentaciones de la realidad. De este modo, una exposición puede
            operar como una apertura entre el conocimiento y la invención, a la
            vez que como una forma experimental y colaborativa de estar en el
            campo.
          </p>
        </div>
      </div>
    </main>
  );
}
