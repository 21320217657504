import React from "react";

export default function RepairBrokennessBreakthrough() {
  return (
    <main>
      <div className="publication-container">
        <div className="publication-upper">
          <img src="media/publications-imgs/7.jpeg" alt="Publication" />
        </div>

        <div className="publication-text mt-5">
          <h2 className="mb-4">
            <a
              className="publication-link"
              target="_blank"
              rel="noreferrer"
              href="https://www.berghahnbooks.com/title/MartinezRepair"
            >
              Repair, Brokenness, Breakthrough. Ethnographic Responses.
            </a>
            <br />
            Edited with Patrick Laviolette.
            <br />
            Berghahn, 2019
          </h2>
          <p>
            This book explains that the relevance of repair is not that it
            happens, but the values attached to it as well as its aesthetic,
            material and moral implications. It engages with theoretical and
            empirical questions such as: What does it mean to claim that
            something is broken? What is the connection between tinkering and
            innovation? And how much tolerance for failure do our societies
            have? Exploring some of the ways in which repair practices and
            perceptions of brokenness vary culturally, the compilation argues
            that repair is an attempt to extend the life of things as well as an
            answer to failures, gaps, wrongdoings and leftovers. The set of
            contributions illustrates the strong affective power hidden in
            situations of disrepair and repair; broken objects often bring
            strong emotions into play, but also reactions of creative action.
          </p>
          <h4>Praise</h4>
          <p className="font-monospace">
            What I like about this book is its richness in ideas, it opens up a
            wide range of issues and associations, it invites the reader to see
            surprising linkages and new aspects of the seemingly trivial
            everyday. There is a lot of inspiration here for a number of
            research fields.
            <br />
            <span className="fw-bold fst-italic text-decoration-underline">
              Orvar Löfgren, University of Lund
            </span>
          </p>
          <p className="font-monospace">
            This is a very original, interesting and critical piece of work. It
            manages to bring the political in touch with the existential in an
            enlightening and, at moments, moving way.
            <br />
            <span className="fw-bold fst-italic text-decoration-underline">
              Paolo Favero, University of Antwerp
            </span>
          </p>
          <p className="font-monospace">
            Francisco Martínez gehören zu der Generation ethnografisch
            forschender Kulturwissenschaftlerinnen, die sich von einem
            Kurzzeitvertrag zum nächsten hangeln und dabei den Wechsel von oft
            weit auseinanderliegenden Standorten in Kauf nehmen müssen. Das
            mindert nicht ihren Mut und ihre Energie, mit neuen Ideen für
            forschend zu erschliessende Felder und alternative, vermittelnde
            Textformate aufzuwarten.
            <br />
            <span className="fw-bold fst-italic text-decoration-underline">
              Regina Bendix, Schweizerisches Archiv für Volkskunde
            </span>
          </p>
        </div>
      </div>
    </main>
  );
}
