import React from "react";

export default function DoingNothingAnthropology() {
  return (
    <main>
      <div className="publication-container">
        <div className="publication-upper">
          <img src="media/publications-imgs/22.JPG" alt="Publication" />
        </div>

        <div className="publication-text mt-5">
          <h2 className="mb-4">
            <a
              className="publication-link"
              target="_blank"
              rel="noreferrer"
              href="https://journals.sagepub.com/doi/abs/10.1177/1466138118782549"
            >
              Doing nothing: Anthropology sits at the same table with
              contemporary art in Lisbon and Tbilisi.
            </a>
            <br />
            Ethnography, 2018
          </h2>
          <p>
            This article proposes an artistic performance to reflect on the
            labour of fieldwork and our economy of attention. The experimental
            method consists in installing myself at a café in Lisbon and Tbilisi
            for 35 hours beyond the reach of smartphones and laptops and then
            doing nothing. Across this exercise, time slows, opening a clearer
            window into ordinary life. The intervention raises questions about
            the way digital technologies transform the temporality and
            experience of ethnographic fieldwork. The essay sets up to make a
            methodological contribution to a growing literature about
            experimental methods, reminding us that observation is a tiring
            physical experience and that slow time is correlated with
            anthropological quality. Doing nothing appears as a slow time being
            in front of others, which enables a break of consciousness, suspends
            politics of relevance, and leaves space for serendipity and embodied
            imagination.
          </p>
        </div>
      </div>
    </main>
  );
}
