import React from "react";

export default function DiggingShadows() {
  return (
    <main>
      <div className="publication-container">
        <div className="publication-upper">
          <img src="media/publications-imgs/46.jpg" alt="Publication" />
        </div>

        <div className="publication-text mt-5">
          <h2 className="mb-4">
            Digging shadows: Making the dark side of Soviet modernity
            perceptible through ethnographic experimentation. In Communist
            Hauntings. Aesthetics of Disrupted Times. M. Schmukalla &amp; L.
            Ozolina.
            <br />
            Palgrave. In Press.
          </h2>
          <p>
            Picking up the underground as a key figure in myths addressing the
            creation of modern infrastructural relations, this chapter discusses
            how mining continues to generate a present and future pollution even
            when the activity is over. Extractivist activities frame the
            territory that frames its people in an alienating way; people who
            relate to institutions and the ecology in a disaffective manner
            later on. Indeed, mining does not simply signal a series of means to
            occupy space economically, but it is, ultimately, a polluting
            culture. The irreversible destruction of the regional soil and
            landscape evidenced the mineral, logistic, and military character of
            Soviet modernity but also of the Anthropocene overall, presenting
            resource exploitation as an inevitable element of progress and
            civilization. Hence, I arranged a series of ethnographic experiments
            exploring the tension between the gestures of extracting and
            depositing, while making Soviet modernity available to the senses.
          </p>
        </div>
      </div>
    </main>
  );
}
