import React from "react";
import { Helmet } from "react-helmet";

export default function Teaching() {
  return (
    <main>
      <Helmet>
        <title>Francisco Martinez - Personal Website</title>
        <meta
          name="description"
          content="Teaching of Francisco Martinez. Teaching Philosophy: Participatory, Inclusive and Creative."
        />
        <meta
          name="keywords"
          content="anthropology, ethnography, material culture, political discourses, repair practices, francisco martinez"
        />
      </Helmet>

      <div className="teaching">
        <div className="container-lg">
          <h2 className="mb-4 text-blue">
            Teaching Philosophy: Participatory, Inclusive and Creative
          </h2>
          <div className="row">
            <div className="col-12 col-md-4 order-md-2 d-flex align-items-center justify-content-center text-center mb-2 mb-md-1">
              <img
                src="media/Teaching1.jpeg"
                alt="Francisco"
                className="img-fluid img-standard img-righty"
              />
            </div>
            <div className="col-12 col-md-8 order-md-1">
              <p>
                My aim as a teacher is to act as an enabler for students to
                become active inquirers of social processes and of the
                materialisation of contemporary transformations through
                ethnographic methods. Accordingly, one of my primary goals in
                teaching is to develop the analytical thinking and research
                skills of the students. As an educator, I try to help students
                to build evidence-based arguments and to consider viewpoints
                other than their own, and then communicate these ideas
                effectively. I encourage students to share their own experiences
                and learn from one another, cultivating observation and
                accessing perspectives beyond their own. Rather than simply
                handing down knowledge in a one-way channel, I facilitate
                multiple learning processes, encouraging students to be aware of
                contemporary issues. Also I strive to provide tools of analysis
                that students can employ outside of the classroom.
              </p>
            </div>
            <div className="col-12 order-md-3">
              <p>
                My classes tend to be horizontal, favouring dialogue and
                multi-modal participation. In my teaching, I connect class
                discussions with the world outside academia and the own living
                experience of students, trying to motivate them for engaging in
                independent work and for reconsidering the prevailing notions of
                relevance. Usually, my course starts by providing the students
                with a general framework of theoretical and research tools
                during the first meetings, challenging their understanding of
                the subject with specific problems, and increasingly
                transferring our focus from lectures to more of dialogue and
                collaborative tasks, which end with intensive independent work
                for a practical project.
              </p>
              <p>
                As I teach, I continue to actively learn how to be a better
                lecturer and supervisor to my students. They also have different
                learning styles, and I seek to accommodate these various ways of
                learning by adapting the pedagogical tools during the process. I
                have experienced that new knowledge is built on previously
                acquired knowledge; thus, a lecturer has to adapt the
                pedagogical material to the experience of the audience and
                constantly exemplify knowledge. Also, the lecturer should be in
                charge of providing a context for thinking and to lead
                discussions about the issues raised, helping to formulate the
                problems, to choose the theoretical framework and methodology,
                to considering alternative perspectives and to push both the
                analytical and writing processes forward.
              </p>
            </div>
            <div className="col-12 col-md-4 order-md-4 d-inline-flex align-items-center justify-content-center text-center mb-2 mb-md-1">
              <img
                src="media/Teaching2.jpg"
                alt="Francisco"
                className="img-fluid img-standard img-lefty"
              />
            </div>
            <div className="col-12 col-md-8 order-md-5">
              <p>
                Teaching in higher education means being in contact with young,
                inspiring yet often challenging people. During the last thirteen
                years, I have acquired a considerable experience in teaching and
                supervision, being always open to collect feedback and use it in
                different ways to develop my teaching. For instance, I have
                found that hands-on exercises with objects or documents are
                effective in stimulating students to think creatively and
                examine information within various frames of reference. This is
                complemented with practical field exercises that allow the
                student to train what they have learnt and to feel the
                excitement of discovery. Likewise, the possibility of presenting
                the findings publicly (in the form of a workshop, a booklet, an
                exhibition), encouraged students to work independently in some
                of the courses I have taught (Material Culture, Ethnographic
                methods, Social Design…)
              </p>
            </div>
            <div className="col-12 order-md-5">
              <p>
                Lecturing in different countries and coordinating pedagogical
                activities in different institutions gave me the muscle needed
                to provide top-level research-based teaching as well as to
                supervise and review theses. For instance, I learnt that there
                are many aspects in supervision that do not directly have to do
                with the object of study or the research task at hand. Also that
                there are other correlated challenges, such as creating a
                supportive environment and strengthening self-confidence for
                making the students apt to disclose their doubts and
                expectations.
              </p>
              <p>
                In 2019, I co-directed the MA programme in Art Museums and
                Gallery Studies at the University of Leicester, where one of my
                students defended her PhD. In 2022, I created the new MA
                programme in Social Design at the Estonian Academy of Arts,
                developing a curriculum at the intersection of design
                anthropology and design philosophy. Overall, I have taught a
                total of 21 different courses in various universities. Also, I
                have given dozens of public lectures and three keynotes.
              </p>
              <p>
                Below I provide the description of some of courses I can teach:
              </p>
            </div>
          </div>
          <div className="row">
            {" "}
            <div id="myaccordion" className="accordion">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    type="button"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    1. Material Culture Now
                  </button>
                </h2>
                <div
                  className="accordion-collapse collapse"
                  id="collapseOne"
                  data-bs-parent="#myaccordion"
                >
                  <div className="accordion-body">
                    <p>
                      Materiality is no longer the sole concern of museum
                      scholars and archaeologists, but a tangential focus of
                      analysis for anyone interested in how objects establish
                      social meanings on behalf of people. This course locates
                      itself in the interface of various material
                      culture-oriented disciplines, namely anthropology, art
                      history, museum studies and archaeology. It focuses on the
                      social and cultural implications of material experiences,
                      examining people’s engagement with things, the affective
                      aspects of this interactions, the significance of things
                      in different contexts, the practices, relations, and
                      rituals through which things become meaningful, as well as
                      how things say about their owners and about social
                      relationships. In this course, we will also discuss other
                      related topics such as consumption, memory, valuation,
                      obsolescence, clothing, aesthetics and digital
                      technologies. Among our reading list, we will have both
                      theoretical surveys as well as context-sensitive and
                      nuanced accounts. Students will gain a conceptual
                      understanding, methodological tools and theoretical
                      knowledge on this field of studies. The independent work
                      will be organised in the form of mini-ethnographies,
                      offering insights into social relations and cultural
                      representations through objects.
                    </p>
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    type="button"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                  >
                    2. Design, Anthropology and the Invention of the Social
                  </button>
                </h2>
                <div
                  className="accordion-collapse collapse"
                  id="collapseTwo"
                  data-bs-parent="#myaccordion"
                >
                  <div className="accordion-body">
                    <p>
                      This course places anthropology and design at the centre
                      of social and ecological issues and develops epistemic
                      tools for dealing with contemporary challenges. It
                      reverses the traditional approach by taking design as an
                      epistemic activity, and anthropology as a material one.
                      The course aims at expanding the interventive capacity of
                      anthropology in societal issues, and how we can
                      reconfigure political problems and their relevance. It
                      starts by assuming that there is a need of equipping the
                      rich middle ground in between ethnographic research and
                      design practice, reflecting on the kind of spaces where
                      different activities can mingle. By this gesture, social
                      scientists have an opportunity to embrace new roles and
                      validate emergent practices that have much to do with this
                      look towards design. Hence, one of the key challenges that
                      we will face is how to connect different communities of
                      practice and traditions, while mutually improving our
                      knowledge-making capacities.
                    </p>

                    <p>
                      This course will also enable a learning of ethnographic
                      methods with and through design practices, exploring novel
                      forms of doing social research together. Overall, we will
                      learn about the productive grounds for conversations
                      between a more designerly ethnography and ethnographic
                      design practices, while accounting of the capacity of
                      anthropology to experiment and advancing on how to
                      reconfigure social problems and their relevance. The
                      course contains theory, ethnographic insights, a guest
                      lecture and a field trip. The independent work will be
                      organised in the form of mini-ethnographies, offering
                      insights into social relations, personal affections and
                      cultural representations through physical things.
                      Practitioners insights and mini-ethnographies will be
                      complemented with group discussions as well as an
                      interdisciplinary review of the relevant body of
                      literature and in relation to our selected case studies or
                      themes.
                    </p>
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    type="button"
                    aria-expanded="true"
                    aria-controls="collapseThree"
                  >
                    3. Repair and Care
                  </button>
                </h2>
                <div
                  className="accordion-collapse collapse"
                  id="collapseThree"
                  data-bs-parent="#myaccordion"
                >
                  <div className="accordion-body">
                    <p>
                      What is gained from studying repair practices? What does
                      it mean to claim that something is broken? And who
                      measures the value of fixing up things? This course
                      engages with contemporary discussions on the
                      re-politicisation of the material by situating care and
                      sustainability at the centre of politics. Repair has a
                      stronger epistemic dimension than regeneration and
                      resilience. It is intimately integrated within the
                      existing ecologies of knowledge, and is both socially
                      embedded and culturally informed, finding nuances rooted
                      within local history, material culture and power
                      relations. This course offers conceptual and
                      methodological training for students to examine how
                      relationships are sustained in relation to the maintenance
                      of built forms, opening up a wide range of questions about
                      responsibility, care-taking, and sustainability.
                    </p>

                    <p>
                      Our primary focus is about people doing things, handling
                      situations: holding on, letting go; but we will also pay
                      attention to the ordering strategies of institutions
                      through repair and maintenance. Using a wide range of
                      methodologies, field techniques, and theoretical
                      approaches we will examine cross-cultural materialities of
                      care and the repair done around us. The fieldwork
                      observation will be complemented with group discussions as
                      well as an interdisciplinary review of the relevant body
                      of literature related to repair and organised in relation
                      to our selected themes, learning to understand that repair
                      is more than a technique.
                    </p>
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    type="button"
                    aria-expanded="true"
                    aria-controls="collapseFour"
                  >
                    4. Ethnographic Methods
                  </button>
                </h2>
                <div
                  className="accordion-collapse collapse"
                  id="collapseFour"
                  data-bs-parent="#myaccordion"
                >
                  <div className="accordion-body">
                    <p>
                      This course sets up to give the student a working
                      familiarity with qualitative research methods and academic
                      writing. It provides advanced introductions to a
                      representative range of field techniques, including
                      ethnography, participant observation, interviews, case
                      studies, surveys, oral history, archival research,
                      artistic experiments, soundscaping and literary
                      descriptions. Students will hone their skills in applying
                      theoretical knowledge to practical research, becoming a
                      generator of reliable and useful information about the
                      world and raising awareness and contributing to the
                      understanding of actual social dynamics. Students will
                      gain a knowledge of issues central to the conduct of
                      qualitative research, such as validity and reliability,
                      the political and ethical implications of social research,
                      and the applicability of particular qualitative methods
                      for particular research approaches and acquisition of
                      funding.
                    </p>

                    <p>
                      Also, throughout our classes, students will learn how to
                      design a meaningful and original research project and
                      undertake the necessary work for collecting data. We will
                      read contemporary works (accessing to inspiring examples
                      and methodologies). The fieldwork observation will be
                      complemented with group discussions as well as an
                      interdisciplinary review of the relevant body of
                      literature related to our selected theme or case studies.
                      The independent work of the students will be organised
                      around their own research interest. The course will also
                      provide practical, hands-on experience in using selected
                      methods. For their assignment, students will write a
                      detailed proposal for a future research project based on
                      preliminary research performed during the course.
                    </p>
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    type="button"
                    aria-expanded="true"
                    aria-controls="collapseFive"
                  >
                    5. Postsocialism and Europeanisation
                  </button>
                </h2>
                <div
                  className="accordion-collapse collapse"
                  id="collapseFive"
                  data-bs-parent="#myaccordion"
                >
                  <div className="accordion-body">
                    <p>
                      This course is designed to reflect on the explanatory
                      value of ‘Post-socialism’ and ‘Europeanisation’ as
                      epistemological categories. It puts the focus on the
                      complex relation between change and continuity brought by
                      different scripts and scales in Eastern European
                      societies. In the first part of the course, we will engage
                      with key theoretical debates in order to distinguish
                      between the conceptual, geographical and historic meaning
                      of these terms. In the second part, we will seek a
                      comparative exercise, analysing relevant case studies and
                      in-depth readings dealing with the experience of radical
                      change and the transition towards a neoliberal market
                      economy.
                    </p>

                    <p>
                      We will face questions about globalisation, generational
                      change and institutional scripts, as well as on the
                      ambiguities of the process of social transformation. This
                      course develops such concerns inquiring on how the EU is
                      assembled in different socio-cultural settings and through
                      complex circulations of regulations, funding and ideas.
                      The learning will help students to articulate a new
                      conceptual vocabulary, epistemologically progressive and
                      with a comparative value and wider theoretical
                      implications.
                    </p>
                    <p>A few major questions are:</p>
                    <ul>
                      <li>What is post-socialism? Is it over?</li>
                      <li>
                        Is there any connection between post-socialism and
                        globalisation?
                      </li>
                      <li>Is post-socialism an urban or rural phenomenon?</li>
                      <li>
                        What was the role of the EU in the unfolding of
                        socio-economic changes?
                      </li>
                      <li>
                        Differences and similarities with post-communism,
                        post-colonialism, globalisation, post-modernism and
                        post-Soviet, post-cold war and transition paradigms.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    type="button"
                    aria-expanded="true"
                    aria-controls="collapseSix"
                  >
                    6. Politics of Memory
                  </button>
                </h2>
                <div
                  className="accordion-collapse collapse"
                  id="collapseSix"
                  data-bs-parent="#myaccordion"
                >
                  <div className="accordion-body">
                    <p>
                      In this course, students will learn about the political
                      and cultural aspects that different forms of remembering
                      have had historically, in the attempt to develop a
                      comparative frame. We will study how historical knowledge
                      is constructed through a combination on material traces,
                      regimes of historicity, sites of memory, as well as
                      contingent and political factors.
                    </p>

                    <p>
                      The anthropological significance of the past will be
                      examined through the study of how individuals and
                      societies make use of memory, material culture and
                      historical discourses to act upon the world. This will be
                      complemented with practical tasks that allow the student
                      to train what they have learnt, as for instance through
                      field exercises, leading to an understanding of how
                      knowledge of the past affects the present.
                    </p>
                    <p>
                      The second part of the course focuses on several practical
                      experiences in which a case study will be interpreted by
                      each student from different perspectives and in different
                      formats. Students are thus supposed to read required
                      literature, to discuss and reflect upon the topics treated
                      in every seminar, to elaborate an individual case study,
                      and to write a final essay about how historical
                      representations influence and inflect our contemporary
                      lives.
                    </p>
                    <p>
                      Through close readings and field research, we will
                      approach the cultural and political dimensions of the past
                      as a force in social life in the present. In our ambition
                      to empirical detail, we will examine how the recent past
                      continues to cause considerable reflection on memory,
                      history and identity-making. A study of these processes
                      allows us learning about the dynamics of social and
                      cultural change, as well as insights into what it means to
                      be European now.
                    </p>
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSeven"
                    type="button"
                    aria-expanded="true"
                    aria-controls="collapseSeven"
                  >
                    7. Home and Dwelling
                  </button>
                </h2>
                <div
                  className="accordion-collapse collapse"
                  id="collapseSeven"
                  data-bs-parent="#myaccordion"
                >
                  <div className="accordion-body">
                    <p>
                      This course critically examines changing notions of home
                      from functional, historical, material, social and
                      aesthetic perspectives. We will discuss the social and
                      political implications of home-making through different
                      case studies, guest lectures, and site visits. As an
                      object of study, home will be approached as both an
                      experience and a cultural category (as for instance, its
                      connection with kinship, the nation, and a cosmology).
                    </p>

                    <p>
                      Students will be exposed to several intellectual and
                      practical housing challenges, expanding knowledge and
                      acquiring valuable experience within the global and local
                      housing challenges and doings of a home. The course is
                      designed to equip students with a broad range of
                      theoretical and practical learnings about the changing
                      meanings, expectations and materials of a home.
                    </p>
                    <p>
                      The course is not simply lecture-based, but will also
                      require classroom discussion, independent work, and
                      auto-ethnographic reflections. It requires active
                      participation through steady reading, writing, and
                      involvement. Students are expected to attend class, read
                      the articles as assigned, participate in field trips, and
                      discussions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
