import React from "react";

export default function PoliticsOfRecuperation() {
  return (
    <main>
      <div className="publication-container">
        <div className="publication-upper">
          <img src="media/publications-imgs/6.jpg" alt="Publication" />
        </div>

        <div className="publication-text mt-5">
          <h2 className="mb-4">
            <a
              className="publication-link"
              target="_blank"
              rel="noreferrer"
              href="https://www.routledge.com/Politics-of-Recuperation-Repair-and-Recovery-in-Post-Crisis-Portugal/Martinez/p/book/9781350133051"
            >
              Politics of Recuperation. Repair and Recovery in Post-crisis
              Portugal.
            </a>
            <br />
            Bloomsbury, 2020
          </h2>
          <p>
            This book studies how Portuguese society has rebuilt itself after
            the crisis. Through a range of ethnographic case studies focusing on
            the Portuguese recovery, it begins a conversation about the
            experience of recuperation and repair. The research addresses how
            recovery adds a human dimension to the public sphere and expands our
            conception of what constitutes the political. Located in the cracks
            between the state and society, this collection takes a thorough look
            at a society in crisis and shows how the people of the community
            create micro-politics of resistance, reflecting on the limits and
            interstices of contemporary politics.
          </p>
          <h4>Praise</h4>
          <p className="font-monospace">
            This book opens a fascinating window into the meaning of personal
            and collective resilience in Europe today. The crisis came, it
            ravaged a whole generation, but did it stop it? No! This is a book
            about rebuilding after the hurricane of financial oppression. But it
            is also a reflexion about the limits and margins of contemporary
            democracy. The Portuguese response to externally enforced
            ‘austerity’ is surely a prime illustration of the creative forces
            that lurk within Europe’s internal margins.
            <br />
            <span className="fw-bold fst-italic text-decoration-underline">
              João de Pina Cabral, University of Kent
            </span>
          </p>
          <p className="font-monospace">
            This book presents a vivid description of the intense financial
            crisis that devastated Southern Europe from 2007 onwards, hitting
            with special intensity the most vulnerable populations. Nonetheless
            the dramatic period, the diverse accounts offer insightful
            ethnographies that describe how citizen inventiveness coped with the
            collapse of their social worlds. They offer us an alternative
            narrative to the one condemning urban dwellers to the role of
            passive subjects of dispossessions. Recuperation is proposed as a
            theoretical figure that unveils that modest activities of mending,
            care and engagement entail a hopeful of reparation of the political
            sphere. This is a book offering hope and resources to dwell in a
            world that seems to be heading to a permanent state of crisis.
            <br />
            <span className="fw-bold fst-italic text-decoration-underline">
              Adolfo Estalella, Madrid Complutense University
            </span>
          </p>
          <p className="font-monospace">
            Transcending the analytical divide between European city contexts
            and those located in the Global South, the different contributions
            in this timely book offer a richly detailed tapestry and in-depth
            exploration of the ‘resonant micropolitics’ of crisis and its
            immediate aftermaths across Portugal. In doing so, this broad range
            of scholars open up anthropological vistas of recuperation and
            repair, thereby enlarging our understanding of the possibilities for
            regenerating living together in today’s broken worlds.
            <br />
            <span className="fw-bold fst-italic text-decoration-underline">
              Filip De Boeck, University of Leuven
            </span>
          </p>
          <p className="font-monospace">
            I really like the ethnographic sensibility that the contributors
            bring to the material and the way the collection approaches socially
            embedded practices of repair and recuperation. Since the experience
            of living under difficult conditions is so widespread in the world
            today, the themes of recuperation and repair make this book useful
            for a broad range of scholars who are interested in how people
            manage their lives under constrained circumstances. At the same
            time, the grounded ethnographic approach makes the book essential
            reading for anyone working in the field of Portugal studies.
            <br />
            <span className="fw-bold fst-italic text-decoration-underline">
              Matt Rosen, Ohio University
            </span>
          </p>
          <p className="font-monospace">
            Martínez’s introduction does a thorough job of setting out the
            generative possibilities for studying brokenness and repair. In sum:
            breakdowns make relations visible; when we understand brokenness and
            repair as processes rather than states, these become productive
            starting points for tracing heterogeneous and intertwined
            relationships that move outwards, involving the constitution of
            wider social and political orders… It explores not only the material
            interrelations of brokenness and repair, but also their ethical and
            affective entanglements…This represents the most compelling
            contribution of the volume as a whole.
            <br />
            <span className="fw-bold fst-italic text-decoration-underline">
              Lara Houston, AJEC
            </span>
          </p>
          <p className="font-monospace">
            Para além da inovação teórico-conceptual forjada pelas ideias de
            recuperação e reparação, o livro dialoga com uma agenda
            epistemológica cada vez mais interessada em desenvolver metodologias
            pós-antropocêntricas, que exaltam a agencialidade das coisas e a sua
            capacidade para reproduzir ou transformar estruturas, relações e
            subjetividades. Além disso, abre novas linhas de investigação nos
            debates sobre a resposta à crise no contexto português, reforçando a
            necessidade de ir além da esfera pública e mapear os esforços
            regenerativos materializados no seio da vida privada… Investigar a
            recuperação é reconhecer a dimensão política do quotidiano e o papel
            desempenhado por pequenos gestos no compor e recompor do mundo.
            Neste contexto, a recuperação é entendida como uma forma de
            micropolítica.
            <br />
            <span className="fw-bold fst-italic text-decoration-underline">
              João Terrenas, ANUAC
            </span>
          </p>
        </div>
      </div>
    </main>
  );
}
