import React from "react";

export default function AgujerosDeRealidad() {
  return (
    <main>
      <div className="publication-container">
        <div className="publication-upper">
          <img src="media/publications-imgs/34.jpeg" alt="Publication" />
        </div>

        <div className="publication-text mt-5">
          <h2 className="mb-4">
            <a
              className="publication-link"
              target="_blank"
              rel="noreferrer"
              href="https://ojs.uc.cl/index.php/Disena/article/view/58759/53799"
            >
              Agujeros de realidad. Des-diseñando el siglo XX en el este de
              Estonia.
            </a>
            <br />
            Diseña 2023
          </h2>
          <p>
            A través del estudio de varios agujeros aparecidos en la ciudad
            minera de Kohtla-Järve, este artículo muestra cómo el trabajo de
            reparación puede adquirir una carga política al re-poner lo social
            en orden y materializar diferentes dimensiones de cuidado. En este
            sentido, la investigación de formas situadas de rotura nos lleva a
            reconsiderar quién tiene, o debe tener, la responsabilidad de
            arreglar aquello roto, y cómo la reparación está ligada a cuestiones
            de futuro y cuidado. La conclusión apunta, primero, a que reparar
            implica afrontar las consecuencias nocivas de decisiones de diseño
            pasadas, tiempo después; segundo, a que, al proyectar futuros
            post-extractivistas, tenemos que afrontar la necesidad de deshacer
            los elementos perniciosos de la modernidad; y tercero, a que las
            críticas sobre el coste social y ecológico de la rotura de
            infraestructuras deben incluir las visiones de la población local.
          </p>
        </div>
      </div>
    </main>
  );
}
