import React from "react";
import { Helmet } from "react-helmet";

export default function Supervision() {
  return (
    <main>
      <Helmet>
        <title>Francisco Martinez - Personal Website</title>
        <meta
          name="description"
          content="Supervision of Francisco Martinez, Organiser of Training Events."
        />
        <meta
          name="keywords"
          content="anthropology, ethnography, material culture, political discourses, repair practices, francisco martinez"
        />
      </Helmet>

      <div className="supervision">
        <div className="container-lg">
          <h2 className="mb-4 d-flex justify-content-center justify-content-md-start text-blue">
            Supervision
          </h2>
          <div className="row">
            <div className="col-12 col-md-4 order-md-2 d-flex align-items-center justify-content-center text-center mb-2 mb-md-1">
              <img
                src="media/Supervision.jpeg"
                alt="Supervision"
                className="img-fluid img-standard img-righty"
              />
            </div>
            <div className="col-12 col-md-8 order-md-1">
              <p>
                I regularly advise and am open to supervising thesis works (at
                all levels) touching upon my anthropological interest in
                material culture, ethnographic experimentation, repair, home and
                inhabitation issues, and anthropology of art.
              </p>
              <p>
                Supervisor of 2 PhD Theses:
                <ul className="mt-2">
                  <li>
                    Blanca Jové, Thinking Institutional Frameworks with Care:
                    The relation between participation and authorship in
                    discursive public programmes (Defended in 2021 at the Univ.
                    of Leicester);
                  </li>
                  <li>
                    Mirimari Väyrynen, Painting as Connector (2023-, Academy of
                    Fine Arts, Helsinki);
                  </li>
                </ul>
              </p>
              <p>
                <ul className="mt-4">
                  <li>
                    Supervisor of MA and BA at University of Leicester, Baltic
                    Film &amp; Media School, Tallinn University, and Tallinn
                    Technical University;
                  </li>
                  <li>
                    Examiner of PhD, MA theses and BA at the University of
                    Leicester, Aalto University, Estonian Academy of Arts,
                    Tallinn University, and Tallinn Technical University.
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <div className="row mt-4 supervision-training">
            <h4 className="text-blue">Organiser of Training Events</h4>
            <p>
              2023 ‘All what we share’, workshop of the #colleex network,
              University of Barcelona
            </p>
            <p>
              2022 ‘Social design as an interdisciplinary practice’, Estonian
              Academy of Arts
            </p>
            <p>
              2020 ‘Academic boundaries at work’, seminar at Leicester Institute
              for Advance Studies
            </p>
            <p>
              2019 ‘Experiments with knowledge’, Estonian Museum of Applied art
              &amp; Design
            </p>
            <p>2018 ‘Peripheral wisdom’, research seminar at the HSE Moscow</p>
            <p>
              2018 Critical Heritage &amp; Museum Studies, department seminars
              at the Univ. of Helsinki
            </p>
            <p>
              2018 ‘Building lives’, Winter School of the Estonian Academy of
              Arts
            </p>
            <p>
              2017 ‘Dialogues between art and anthropology’, roundtable at the
              EKA Gallery
            </p>
            <p>
              2014 ‘Sport &amp; Diplomacy’ at the British Embassy to Estonia,
              13th EASA conference
            </p>
            <p>
              2014 ‘Urban Youth and Social Acceleration’, Summer School at
              Tallinn University
            </p>
            <p>
              2013–2014 ‘Inimkond’, Department seminars at Tallinn University
            </p>
          </div>
        </div>
      </div>
    </main>
  );
}
