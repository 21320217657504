import React from "react";

export default function LightsOutPracticingOpacity() {
  return (
    <main>
      <div className="publication-container">
        <div className="publication-upper">
          <img src="media/publications-imgs/45.jpeg" alt="Publication" />
        </div>

        <div className="publication-text mt-5">
          <h2 className="mb-4">
            Lights Out, Practicing Opacity.
            <br />
            Etnográfica, 2023.
          </h2>
          <p>
            This Forum discusses the ‘right to opacity’ in an age of visual
            excess, tracking and exposure. Based on my ethnographic wanderings
            in shadow spaces across eastern Estonia, I argue for the
            epistemological potential of such environments, in terms of
            questioning the hegemonic and alternative regimes of visibility we
            engage with.
          </p>
          <p>
            But what kind of political and epistemological consequences can we
            take from such an approach? Indeed, the absence of the conditions
            for visually coded observations and the opacity of our experience in
            the field demanded the continuation of ethnography by other means,
            even if this might entail lacking the standardization typically
            attributed to methods.
          </p>
          <p>
            The essay is followed by reactions from Patrick Laviolette, Hermione
            Spriggs, Tamta Khalvashi and Mariana Tello Weiss.
          </p>
        </div>
      </div>
    </main>
  );
}
