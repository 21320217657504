import React from "react";

export default function HopelessYouth() {
  return (
    <main>
      <div className="publication-container">
        <div className="publication-upper">
          <img src="media/publications-imgs/31.jpg" alt="Publication" />
        </div>

        <div className="publication-text mt-5">
          <h2 className="mb-4">
            Hopeless Youth! With P. Runnel
            <br />
            Estonian National Museum 2015
          </h2>
          <p>
            <span className="fst-italic">Hopeless Youth!</span> is a collection
            of studies exploring what it means to be young today. It is the
            youth with the greatest hunger of experiences and cosmopolitan
            referents. Youngsters are hopeless because they do not expect help
            from anybody and they demonstrate scepticism about the future. As
            shown, contemporary youth is characterised by interim responses and
            situational thinking, developing particular skills that do not exist
            in previous generations. The kaleidoscope of youth cultures is
            mirrored by topics such as punks, hiphoppers, hikers, chavs, migrant
            youth, children's art, to name a few. Geographically, the different
            chapters cover a number of European countries, from Russia to the
            UK.
          </p>
          <h4>Praise</h4>
          <p className="font-monospace">
            <span className="fst-italic">Hopeless Youth!</span> is a fascinating
            and diverse collection of essays about what some have refereed to as
            counter-cultural or sub-cultural interests. Taken as a whole
            however, this collection of work makes abundantly clear that the
            writing, thinking and doing involved in punk and hip hop culture,
            flâneurism, dubstep and techno music scenes, skateboarding, dumpster
            diving and hitchhiking, for example, are central to culture on a
            more-than-marginal level. This collection of essays is bound to be a
            staple reference for anyone working with groups and individuals
            defining places their own terms.
            <br />
            <span className="fw-bold fst-italic text-decoration-underline">
              Bradley L. Garrett, University of Oxford.
            </span>
          </p>
          <p className="font-monospace">
            This volume is a bold and expressive statement. The exclamation mark
            in the title sums it up for me. Make what you will of this
            collection – that seems to be the point. It is a timely addition to
            a type of scholarship which is proactive, progressive and
            provocative. With a diverse and international range of contributors,
            this visually stunning book is playful yet no less politically
            challenging to the dogmas of intellectual lethargy. And in harboring
            an array of sharp ideas, unconventional themes, creative forms of
            dissemination and imaginative collaborations, this collection will
            have its readers repeatedly thumbing through its pages.
            <br />
            <span className="fw-bold fst-italic text-decoration-underline">
              Patrick Laviolette, Tallinn University
            </span>
          </p>
          <p className="font-monospace">
            The neoliberalisation of aspects of social life has laid a
            precarious and uncertain path for young people as they become
            increasingly more ‘liberated’ to cultivate their own identities.
            Across Western societies, many immerse themselves in virtual worlds,
            musical tastes and engage in risky behaviors as a ‘live for today’
            philosophy takes power over youth identity politics. In the
            background, however, inequality and unemployment increase while
            resistance movements against problematic politics gathers momentum.
            There has never been a more interesting time to examine the concept
            of ‘youth’ and elaborate on its future. This eclectic mix of
            commentators who have contributed chapters in Hopeless Youth whose
            expertise include subculture, postmodernity, and identity politics
            make this collection an important milestone in the discussion of the
            status quo and future of the most important generation to come.
            <br />
            <span className="fw-bold fst-italic text-decoration-underline">
              Daniel Briggs, European University of Madrid
            </span>
          </p>
          <p className="font-monospace">
            This book is a refreshing read for anyone interested in the broad
            field of youth. Among the topics and groups covered in the volume,
            every reader can find several chapters relevant to his or her
            studies. The message of the book can be defined through the term
            ‘glocalisation’, where practices of youth in different countries are
            incredibly similar to each other, yet having developed their own
            unique national accents.
            <br />
            <span className="fw-bold fst-italic text-decoration-underline">
              Aimar Ventsel, Social Anthropology
            </span>
          </p>
        </div>
      </div>
    </main>
  );
}
