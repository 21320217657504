import React from "react";

export default function AnaloguePhotoBooths() {
  return (
    <main>
      <div className="publication-container">
        <div className="publication-upper">
          <img src="media/publications-imgs/20.jpeg" alt="Publication" />
        </div>

        <div className="publication-text mt-5">
          <h2 className="mb-4">
            <a
              className="publication-link"
              target="_blank"
              rel="noreferrer"
              href="https://www.therai.org.uk/images/stories/photography/AnthandPhotoVol9.pdf"
            >
              Analogue photo booths in Berlin: A stage, a trap, a condenser, and
              four shots for kissing the person you love.
            </a>
            <br />
            Anthropology and Photography, 2018
          </h2>
          <p>
            Why do analogue photos still fascinate young people? Why, for some
            purposes, might vintage technologies be considered more authentic
            than newer ones? And what is the contribution of old-school photo
            booths to Berlin as a city? Based on participant observation and 60
            interviews with users, the research shows how the relationship
            between these old-school booths and Berlin is reciprocal, becoming
            part of the city’s scene, assembling people, displaying and
            materialising relationships, thereby providing an opportunity to be
            private in public. In casting an ethnographic eye on analogue photo
            booths in Berlin, this article makes a case for the continued
            relevance of analogue technologies and practices in the
            con-temporary digital age. The research highlights the
            inconsistencies in the linear theories of media development and
            social change, therefore pointing to a complex co-existence of
            actual and emerging technologies and practices.
          </p>
        </div>
      </div>
    </main>
  );
}
