const exhibitionsData = [
  {
    id: 1,
    describtion:
      '"Misreadings", with V. Gurov. Container Gallery of the Telliskivi Creative City, 2024',
    image: "1.jpg",
  },

  {
    id: 2,
    describtion:
      '"General Theory of Abandoned Books", with V. Gurov. Glass Box of the Solaris Center (Tallinn), 2024',
    image: "2.jpg",
    hyperlink:
      "https://news.err.ee/1609362296/gallery-abandoned-books-on-display-in-solaris-glass-house",
  },
  {
    id: 3,
    describtion: '"Ex Libris", with V. Gurov, SillArt (Sillamäe), 2024',
    image: "3.jpeg",
    hyperlink:
      "https://news.err.ee/1609331730/feature-new-art-exhibition-explores-sillamae-library-that-was-left-behind",
  },
  {
    id: 4,
    describtion:
      '"Keeping Things in the Dark", with A. Škodenko, D. Popolitova, and V. Gurov. In Riga Art Hall, Sillamäe Museum, EKA Gallery, 2022/2023',
    image: "4.jpeg",
    hyperlink:
      "https://arterritory.com/en/visual_arts/on_site/26903-keeping_things_in_the_dark_again/",
  },
  {
    id: 14,
    describtion:
      '"Design and Be Designed: Speculations on Sustainability", with M. Esko. Roheline Saal, Telliskivi Creative City, 2022',
    image: "14.jpg",
    hyperlink: "",
  },
  {
    id: 5,
    describtion: '"Life in Decline". Estonian Mining Museum, 2021',
    image: "5.jpeg",
    hyperlink:
      "https://deepbaltic.com/2021/09/09/life-in-decline-artists-on-estonias-post-industrial-east/",
  },
  {
    id: 6,
    describtion:
      '"Objects of Attention", Estonian Museum of Applied Art and Design, 2019',
    image: "6.jpg",
    hyperlink:
      "https://blogs.helsinki.fi/chstudies/2019/01/01/exhibition-objects-of-attention-experiments-with-knowledge-in-the-state-museum/",
  },
  {
    id: 7,
    describtion:
      '"Greetings from Another Time and Space", with M. Agu. Contemporary Art Museum of Estonia, 2018',
    image: "7.jpg",
    hyperlink:
      "https://mustekala.info/knowledge-speculation-during-climate-crises-when-you-say-we-belong-to-the-light-we-belong-to-the-thunder-at-contemporary-art-museum-of-estonia-ekkm/",
  },
  {
    id: 8,
    describtion:
      '"System & Error". Contemporary Art Museum of Estonia, EKKM, 2018',
    image: "8.jpg",
    hyperlink:
      "https://www.artun.ee/en/calendar/eka-aalto-student-exhibition-system-error-at-ekkm/",
  },
  {
    id: 9,
    describtion:
      '"Help to the Stoker of the Central Heating Boiler", with Kirill Tulin. Contemporary Art Museum of Estonia, 2017',
    image: "9.jpg",
    hyperlink:
      "https://isolartcenter.org/en/blog/2017/11/22/abiks-keskkuttekatla-kutjale-aiuto-fuochista-della-caldaia-del-riscaldamento-centrale/",
  },
  {
    id: 10,
    describtion:
      '"Aesthetics of Repair in Georgia", with M. Agu. Tartu Art Museum, 2016',
    image: "10.JPG",
    hyperlink:
      "https://artmargins.com/aesthetics-of-repair-in-contemporary-georgia-tartu-art-museum-estonia-march-24-may-29-2017/",
  },
  {
    id: 11,
    describtion: '"Place Oddity", with L-K. Repnau. EKA Gallery, 2016',
    image: "11.png",
    hyperlink:
      "https://arterritory.com/en/visual_arts/articles/16195-when_contemporary_art_flirts_with_anthropology/",
  },
  {
    id: 12,
    describtion:
      '"I looked into the Wall and Saw", with M. Krivy. ISFAG,, Tallinn, 2014',
    image: "12.jpg",
    hyperlink: "https://ekasculpture.tumblr.com/post/109907941838",
  },
  {
    id: 13,
    describtion:
      '"The Railway Street Market Goes to the Gallery", EKA Gallery, 2014',
    image: "13.png",
    hyperlink:
      "https://kultuur.err.ee/298842/kunst-joudis-balti-jaama-turult-galeriisse",
  },
];

export default exhibitionsData;
