import React from "react";

export default function InsightsIntoTheDemolition() {
  return (
    <main>
      <div className="publication-container">
        <div className="publication-upper">
          <img src="media/publications-imgs/44.jpg" alt="Publication" />
        </div>

        <div className="publication-text mt-5">
          <h2 className="mb-4">
            Delayed and Displaced Gifts.
            <br />
            With O. Pyyhtinen.
            <br />
            Ethnologia Europaea, Forthcoming.
          </h2>
          <p>
            Gifts are deeply seated in the organisation of social life. We all
            give and receive gifts and tend to think of the rituals of exchange
            as both ubiquitous and rather pleasing. But what happens when the
            usual traffic of gifts is delayed or displaced? Foregrounding the
            diversity of gift-giving practices as well as the heterogeneity and
            contingency of their meanings, we examine objects, gestures, and
            performances of giving that might not fit the classical
            anthropological definitions of what a gift is and does. Our
            intention, however, is not to so much to replace classical
            anthropological gift theories as to supplement them and carry them
            forward by exploring contemporary forms of gifting. Indeed, we
            suggest that the Maussian model still serve to understand practices
            of reciprocity in late-industrial societies, yet this Special Issue
            contributes to delineate the heuristic potential and limitations of
            classical models in the present.
          </p>
        </div>
      </div>
    </main>
  );
}
