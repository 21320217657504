import React from "react";

export default function DiggingShadows() {
  return (
    <main>
      <div className="publication-container">
        <div className="publication-upper">
          <img src="media/publications-imgs/47.jpeg" alt="Publication" />
        </div>

        <div className="publication-text mt-5">
          <h2 className="mb-4">
            ‘Europe(s)’. With A. Vonderau, C. Brkovic &amp; E. Saleh. In
            Anthropology in Europe: Re- turns, Entanglements and Generations. J.
            Tošić, S. Strasser &amp; A. Lems eds.
            <br />
            Berghahn, in press.
          </h2>
          <p>
            Rather than offering an exhaustive literature review of
            anthropological writings on Europe, we have chosen to produce a
            deliberately partial and incomplete text that mirrors the partiality
            and fragmented nature of Europe(s). We aim to delve into the
            complexities, opportunities and inequalities encapsulated by the
            term ‘Europe’ by providing insights from four situated and distinct
            perspectives, grounded in concrete empirical research fields and
            academic biographies. We understand Europe(s) as a multitude of
            open-ended and multi-scalar political, economic and cultural
            projects: as products and production sites of powerful, unevenly
            distributed and dynamic socio-material relations and entanglements.
          </p>
          <p>
            All the four individual reflections emphasize the concept of
            ‘return’, shedding light on how Europe(s) are currently being
            reshaped with an eye towards the future. Today, Europe(s) are
            undergoing profound transformations, characterized by a pervasive
            sense of threat and suspended time, embattlement and a succession of
            crises that hinder the envisioning of a future. In the collaborative
            process of writing this text, we encountered some aspects of the
            European project itself. The composition of the writing team was
            carefully chosen to capture the diversity and inequality across
            different perspectives on Europe. In our work, we have encountered
            various instances, faces and reverberations of Europe(s), across
            centres, peripheries, Easts, Wests, Norths and Souths.
          </p>
          <p>
            In various ways, we have explored how Europe(s) emerge and dissolve
            in a relational and uneven manner, opening opportunities for some,
            while closing doors for others. Asta, for instance, looks at violent
            environments and technological zones between Europe’s East and West,
            and at overlapping regional and planetary scales of fossil
            modernity’s crisis. Elizabeth explores the entanglements of Europe
            and the so-called Levant. Čarna examines the Balkans, Europe and the
            Non-Aligned world; while Francisco studies Narva, the political and
            cultural edge of Europe, by paying attention to standardized
            practices of giving and receiving.
          </p>
          <p>
            While writing this text, we have actively worked to align our
            situated and partial perspectives through continuous conversations,
            revisions, redesigns and regular check-ins with the rest of the
            team. The result of this collaboration sheds light on what might be
            recursively reiterated across different scales when a group of
            people converse with one another over an extended period of time.
          </p>
        </div>
      </div>
    </main>
  );
}
