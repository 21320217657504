import React from "react";

export default function CoethnographersInThestorm() {
  return (
    <main>
      <div className="publication-container">
        <div className="publication-upper">
          <img src="media/publications-imgs/26.jpg" alt="Publication" />
        </div>

        <div className="publication-text mt-5">
          <h2 className="mb-4">
            Co-ethnographers in the storm: Investigating post-socialist decline
            with contemporary artists. In{" "}
            <a
              className="publication-link"
              target="_blank"
              rel="noreferrer"
              href="https://www.routledge.com/The-Trouble-With-Art-An-Anthropology-Beyond-Philistinism/Sansi-Tinius/p/book/9781032223919"
            >
              The Trouble with Art.
            </a>
            <br />
            R. Sansi and J. Tinius.
            <br />
            Routledge, 2024
          </h2>
          <p>
            This chapter shows that curating art exhibitions in the form of
            social research can be a useful troubling, opening the ranges of
            what we may take anthropology to be, as much as reconfiguring the
            practice of ethnography. Here I reflect on the research conducted
            while curating the Life in Decline exhibition at the Estonian Mining
            Museum in 2021. In this project, artists were involved not simply as
            a reality check on my interpretations, but also as my
            co-ethnographers with whom to share analytical capacities and
            produce knowledge through experimental collaborations. One of the
            implications of this gesture is that the anthropologist not just
            makes arguments about artists in the field, but with them –
            introducing new representations into the social life that we are
            studying. Artists proved to be knowledge-makers themselves,
            co-ethnographers able to do field research and produce valuable
            analytical knowledge about local decline – by means of the elements
            of exhibition making.
          </p>
        </div>
      </div>
    </main>
  );
}
