import React from "react";

export default function WasteIsNotTheEnd() {
  return (
    <main>
      <div className="publication-container">
        <div className="publication-upper">
          <img src="media/publications-imgs/24.jpeg" alt="Publication" />
        </div>

        <div className="publication-text mt-5">
          <h2 className="mb-4">
            <a
              className="publication-link"
              target="_blank"
              rel="noreferrer"
              href="https://onlinelibrary.wiley.com/doi/abs/10.1111/1469-8676.12436"
            >
              Waste is not the end. For an anthropology of care, maintenance and
              repair.
            </a>
            <br />
            Social Anthropology, 2017
          </h2>
          <p>
            Decisions to abandon or rehabilitate are always informed by value
            judgements, not simply by the cost of time, money or effort
            required. Hence the importance of practices of maintenance and the
            corresponding need to complement the anthropological analysis of
            discard with repair studies. Repair has consequences for how we
            think of social relations by lending continuity to discontinuity,
            helping us to overcome the negative logic that carries the
            abandonment of things and to reconnecting personal biographies to
            public materiality. We can situate repair as part of the everyday
            micro-powers, those that contribute to create transcendental
            narratives of reconstitution after wrongdoing or abandonment. This
            discussion eventually reveals that brokenness is never final,
            indifferent, autonomous, impervious to change. Rather, it is an
            in-between condition, waiting for a new life, available for new
            relationships and reconstitutions.
          </p>
        </div>
      </div>
    </main>
  );
}
