import React from "react";
import { Helmet } from "react-helmet";

export default function TheFutureOfHiding() {
  return (
    <main>
      <Helmet>
        <title>Francisco Martinez - The Future Of Hiding</title>
        <meta
          name="description"
          content="The Future of Hiding. Cornell University Press, 2025. Alina Jašina-Schäfer, University of Mainz. Giorgi Cheishvili, Tbilisi State University."
        />
        <meta
          name="keywords"
          content="anthropology, ethnography, material culture, political discourses, repair practices, francisco martinez"
        />
      </Helmet>

      <div className="publication-container">
        <div className="publication-upper">
          <img src="media/publications-imgs/1_original.jpg" alt="Publication" />
        </div>

        <div className="publication-text mt-5">
          <h2 className="mb-4">
            The Future of Hiding. Secrecy, Belonging, and Energy Infrastructure
            in Estonia’s Siberia.
            <br />
            Cornell University Press, 2025.
          </h2>
          <p>
            The Future of Hiding analyzes the territorial dimensions of secrecy
            and how concealment takes place in relation to energy infrastructure
            and identity politics in Eastern Estonia. The research highlights
            the way in which basements, garages, bunkers, and summer cottages
            favor alternative forms of sociality, allowing local residents to
            redesign the very terms of their public being in a condition of
            negative capability. Field research in this liminal region, at the
            border with Russia, provides a dearth of information about
            ecological memory, recolonization, and what does not disappear
            properly. Therefore, this book expands the space for locating
            culture, politics, and the reproduction and destruction of
            infrastructure, showing, in turn, various strategies, such as
            refusal and concealment, used by individuals to navigate the
            consequences of an incomplete belonging and a century of extractive
            activities.
          </p>

          <div className="publication-preface">
            <h4>Content</h4>
            <ul className="d-flex flex-column align-items-start fst-italic">
              <li className="">Preface: Calypso in the Shadows</li>
              <li>Introduction: Hiding in the Future</li>
              <li>1. Keeping Things in the Dark</li>
              <li>2. A Wound that Gives Off a Dark Light</li>
              <li>3. New Hideouts for an Old Fear</li>
              <li>4. Making Room for the Future</li>
              <li>5. Off the Map, Not to Be Found</li>
              <li>6. A Garage with a View</li>
              <li>7. Crypto-Colonialism</li>
              <li>Conclusion: An Outside Inside</li>
            </ul>
          </div>

          <h4>Endorsements</h4>
          <p className="font-monospace">
            Hideouts, basements, garages—Martínez’s ethnographic attunement to
            “shadow spaces” in eastern Estonia offers readers an insightful
            glimpse into the sociality and enduring knowledge repertoires of a
            region simultaneously relegated to the margins and subjected to
            processes of recolonization via resource extraction. With its
            careful attention to the affective and material dimensions of
            everyday interactions with and in these liminal places, the book
            tells an intriguing story of memory, identity, and belonging in a
            post-socialist context. Indeed, The Future of Hiding is
            methodologically innovative, theoretically sophisticated, and deeply
            evocative, modeling the very best of what ethnography has to offer.
            <br />
            <span className="fw-bold fst-italic text-decoration-underline">
              Katie Kilroy-Marac, University of Toronto
            </span>
          </p>
          <p className="font-monospace">
            In this probing ethnography of secrecy on the frontiers of Europe,
            Martínez craftly tells the stories of those lives that have refused
            visibility in the midst of post-socialist devaluations and
            demolitions. In basements, bunkers, and garages the people excluded
            from the nation-building process create spaces of opacity, and with
            it, alternative modes of belonging. Through an innovative attention
            to social margins and energy infrastructures, Martínez calls into
            question the essentialist understandings of identity and provides a
            careful account of the topographical dimension of secrecy and its
            contribution to social order. This is a deeply humane book, written
            with great sympathy for the people it describes and bearing the
            marks of a work matured by a decade of fieldwork.
            <br />
            <span className="fw-bold fst-italic text-decoration-underline">
              Kiven Strohm, National University of Singapore
            </span>
          </p>
          <p className="font-monospace">
            The Future of Hiding makes a significant contribution to our
            understanding of people, places, and things that are perceived as
            out of place and time, often relegated to a corner as disruptions to
            the social order. Drawing on ethnographic research in Eastern
            Estonia, a region considered residual within the country’s context,
            Martínez demonstrates that the colonial character of extractivism is
            not that far past. These elements of infrastructural harm stem from
            present forms of domination that dictate modes of affiliation with
            the nation-state and foster a pervasive sense of precarity and
            uncertainty.
            <br />
            <span className="fw-bold fst-italic text-decoration-underline">
              Alina Jašina-Schäfer, University of Mainz
            </span>
          </p>
          <p className="font-monospace">
            This original book examines how infrastructures are lived in the
            borderlands between Russia and the European Union. The ethnography
            offers a thought-provoking examination of the gradual decomposition
            of a long-disappeared polity—the Soviet Union. Drawing on fieldwork
            in Estonia—an area often overlooked, Martínez transcends
            disciplinary boundaries to study topics ranging from post-socialism
            and colonialism to place-making, social organization, and identity
            formation. His work is not only meticulously researched but also
            deeply engaging.
            <br />
            <span className="fw-bold fst-italic text-decoration-underline">
              Giorgi Cheishvili, Tbilisi State University
            </span>
          </p>
        </div>
      </div>
    </main>
  );
}
